import React, { useEffect } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Productcard from '../Components/Productcard';
import { useSearchParams } from 'react-router-dom';
import $ from 'jquery';
import BackToTop from '../Components/BackToTop';

// menüdeki ürün ismiyle ürünlerdekinin aynı olması gerekiyor çalışması için (küçük-büyük harf vs)
function Products() {
  const [searchParams] = useSearchParams();
  const productTitle = searchParams.get('title');

  // setTimeout(() => {
  //   window.addEventListener("scroll", () => {
  //     document.querySelector(".ant-dropdown").style.top = "-500px";
  //   });
  // }, 1000);

  useEffect(() => {
    if (!!productTitle) {
      const $elementToScroll = $(`.productcard:contains(${productTitle})`)[0] || $(`body`)[0];

      setTimeout(
        () =>
          $elementToScroll.scrollIntoView({
            behavior: 'smooth',
          }),
        500
      );
    }
  }, [window.location.href]);

  let products = {
    antiSeizeCopperPaste: {
      title: 'Anti-Seize Copper Paste',
      description: `Almilon Anti-Seize Copper Paste is a non-curing, copper-colored anti-seize lubricant designed to prevent high-temperature seizing and galling. It ensures easier removal of mated parts like studs, bolts, flanges, and gaskets, maintaining them in cleaner and better condition. It is compatible with metals (including stainless steel), alloys, plastics, and non-metallic gasketing materials. Typical uses include applications in petroleum, steel mills, power plants, marine industries, and foundries, operating between -29°C to +982°C.

Key Properties:

Density: 1.27 g/ml at 25°C
Specific Gravity: 1.2-1.4
Non-Volatile Content: 40%
Penetration: 320-380 (ISO 2137)

Performance Benefits:

Helps develop higher and more uniform clamp loads in bolts.
Torque coefficient for lubricated bolts: 0.16 (compared to 0.27 for unlubricated bolts).

Usage Instructions:

Clean mating surfaces before application.
Apply a thin layer to threads and flats of nuts and bolts.
Use a dust mask if grinding or wire brushing threads to prevent inhalation of dust.`,
      package: '500gr box, 1kg Box, 10kg Pail, 25kg Pail',
      image: ' ',
    },
    antiSeizeNickelPaste: {
      title: 'Anti-Seize Nickel Paste',
      description: `Almilon Anti-Seize Nickel Paste is a non-curing, nickel-based, high-temperature anti-seize lubricant designed for heavy-duty applications. It prevents galling, corrosion, and wear, particularly in stainless steel parts, and operates effectively in a temperature range of -54°C to +1,315°C. It is resistant to acids, ammonia, acetylene, and vinyl monomers, making it ideal for harsh environments.

Key Features:

Chemical Composition: Mineral oil, nickel, graphite
Appearance: Thick metallic silver paste
Viscosity: 650,000 to 1,050,000 mPa·s at 25°C
Specific Gravity: 1.1
Salt Fog Corrosion Resistance: 168 hours (ASTM B 117)

Typical Applications:

Drop forge dies, catalyst bed supports, and conveyor chains
Pipe threads in acid environments
Chemical plant bolts, pump housings, and high-temperature gaskets

Important Notes:

Not suitable for high-speed load-carrying parts like ball or roller bearings.
Avoid using on high-stress Grade 8 bolts prone to stress corrosion.
Not recommended for pure oxygen or chlorine-rich systems.`,
      package: '500gr box, 1kg Box, 10kg Pail, 25kg Pail',
      image: ' ',
    },
    antiSeizeAluminiumPaste: {
      title: 'Anti-Seize Aluminium Paste',
      description: `Almilon Aluminium Anti-Seize Paste is a grey, non-curing anti-seize lubricant designed to protect threaded connections exposed to high temperatures (up to 900°C). It prevents seizing, jamming, and corrosion in high-temperature environments, such as exhaust systems and oil or gas burners. The product operates effectively in a temperature range of -30°C to +900°C.

Key Properties:

Density: 0.9 g/ml at 25°C
Consistency: NLGI Class 1
Penetration (Worked): 310-340 (1/10mm)
4-Ball Weld Load Test: 4,400 N
Wear (1 hour/400 N): 0.95 mm
Copper Corrosion Resistance: 1b at 100°C for 3 hours

Usage Instructions:

Apply to clean parts; avoid excessive lubrication.
Not suitable for pure oxygen, oxygen-rich systems, or strong oxidizing materials.`,
      package: '500gr box, 1kg Box, 10kg Pail, 25kg Pail',
      image: ' ',
    },
    antiSeizeCeramicPaste: {
      title: 'Anti-Seize Ceramic Paste',
      description: `Almilon Anti-Seize Ceramic Paste is a non-curing, grey anti-seize lubricant formulated with graphite and calcium fluoride. It is free of lead, copper, sulfur, and their compounds, making it suitable for stainless steel and nickel alloys. It is designed for high-temperature applications (operating range: -29°C to +1315°C) and is widely used in industries such as chemical plants, oil refineries, power plants, steel mills, aerospace, and more. Typical uses include bolts, studs, pipe joints, dies, heat exchangers, and metal bending equipment.

Key Properties:

Specific Gravity: 1.15
Penetration: 270-370 (ISO 2137)
Weight per Gallon: 9.6-10.6 lbs

Benefits:

Enhances clamp load uniformity in bolts.
Torque coefficient: 0.16 (lubricated) vs. 0.27 (unlubricated).

Usage Instructions:

Clean and degrease surfaces before application.
Apply a thin layer to mating surfaces.
Use a dust mask if grinding or wire brushing threads to prevent inhaling dust.
Avoid using thinner with this product.`,
      package: '500gr box, 1kg Box, 10kg Pail, 25kg Pail',
      image: ' ',
    },
    siliconeGrease: {
      title: 'Silicone Grease',
      description:
        'Silicone grease can be used for a wide temperature range, generally from -60°C to 300°C even with varying pressure or load conditions.\n Silicone grease with PTFE filler can be used for electrical applications due to their electrically insulating properties.\n The silicone oil present in the grease is water repellant, thus, can be used in applications where long-term protection against oxidation is desired.\n In some cases, silicone lubricant grease with high viscosity can also be used as a sealant.\n The non-toxic nature of silicone grease makes it possible to use in medical applications as well as in food-grade facilities.',
      package: '500gr box, 1kg Box, 10kg Pail, 25kg Pail',
      image: ' ',
    },
    graphiteSiliconeGrease: {
      title: 'Graphite Silicone Grease',
      description:
        'High Temperature Graphite Grease provides a residual film of low coefficient of friction which enhances its recommendation for industrial application. This graphite-based grease forms a lubricating film that prevents metal-to-metal contact by separating frictional surfaces. It is excellent for use where operating temperatures reach up to 700°C).',
      package: '500gr box, 1kg Box, 10kg Pail, 25kg Pail',
      image: ' ',
    },
    PTFESiliconeGrease: {
      title: 'PTFE Silicone Grease',
      description:
        'High Temperature PTFE Grease provides a residual film of low coefficient of friction which enhances its recommendation for industrial application. This graphite-based grease forms a lubricating film that prevents metal-to-metal contact by separating frictional surfaces. It is excellent for use where operating temperatures reach up to 220°C).',
      package: '500gr box, 1kg Box, 10kg Pail, 25kg Pail',
      image: ' ',
    },
    MoS2SiliconeGrease: {
      title: 'MoS₂ Silicone Grease',
      description:
        'High Temperature MoS₂ Grease provides a residual film of low coefficient of friction which enhances its recommendation for industrial application. This graphite-based grease forms a lubricating film that prevents metal-to-metal contact by separating frictional surfaces. It is excellent for use where operating temperatures reach up to 450 °C).',
      package: '500gr box, 1kg Box, 10kg Pail, 25kg Pail',
      image: ' ',
    },
    contactSpray: {
      title: 'Contact Spray',
      description:
        'Contact Cleaner is a plastic-safe general-purpose evaporating cleaner and degreaser for use on sensitive electronic and electrical equipment. It is formulated to quickly penetrate into hard-to-reach areas and effectively flush away carbon deposits, dirt, light oils, dust, lint and other light contaminants.',
      package: '200ml, 400ml, 500ml',
      image: ' ',
    },
    degreaserSpray: {
      title: 'Degreaser Spray',
      description:
        ' Degreaser Spray is a non-flammable heavy-duty evaporating cleaner and degreaser for use on electric motors and other heavy-duty electrical and mechanical equipment. The stabilised fast-acting solvent blend effectively removes wax, grease, oil, dirt, moisture and foreign matter that are common causes of current leakages and electric inefficiencies. It minimises downtime by cleaning low-voltage motors and equipment while in operation.',
      package: '200ml, 400ml, 500ml',
      image: ' ',
    },
    smokeTestSpray: {
      title: 'Smoke Test Spray',
      description:
        'Smoke Test Spray simulates smoke to conveniently test the complete functional ability of commercial and residential smoke detectors. Unlike the test button on the detector, it not only checks the batteries but also tests for obstruction or debris that may clog detector vents and prevent smoke from entering the sensing chamber.',
      package: '200ml, 400ml, 500ml',
      image: ' ',
    },
    rustRemoverSpray: {
      title: 'Rust Remover Spray',
      description:
        ' Rust Remover Spray is an industrial strength, low viscosity lubricant and penetrant designed to quickly loosen and free seized, bound or frozen fasteners. Spray is quickly permeates rust, scale, gum, grease and corrosion with a low surface tension liquid penetrant formula. It creeps into cracks, seams, threads and joints with great efficiency and leaves a thin non-drying colourless film with outstanding lubrication and corrosion inhibiting properties',
      package: '200ml, 400ml, 500ml',
      image: ' ',
    },
    brakeCleanerSpray: {
      title: 'Brake Cleaner Spray',
      description:
        ' Brake Cleaner Spray is a powerful, heavy duty cleaner and degreaser for brake, clutch parts and general mechanical equipment. It is formulated to quickly and safely dissolve and flush away grease, oil, brake fluid, transmission fluid, hardened deposits and other contaminants, without the need for disassembly - reducing maintenance costs.',
      package: '200ml, 400ml, 500ml',
      image: ' ',
    },
    siliconeSealantRTV1: {
      title: 'Neutral Silicone Adhesive (RTV1)',
      description:
        'This premium neutral silicone adhesive is designed for versatile applications, offering exceptional durability and performance. It cures effectively under water and at ambient temperatures, ensuring reliable adhesion in challenging environments. Key features include:\n\nSuperior Adhesion Strength: Bonds securely to a wide range of materials.\nElastic and Flexible: Retains flexibility after curing, accommodating structural movement and vibration.\nResistant to Harsh Conditions: Unaffected by prolonged exposure to sea water and UV radiation, ensuring long-term reliability.\nHigh Thermal Tolerance: Operates effectively in temperatures up to 250°C without compromising performance.\nIdeal for marine, industrial, and general-purpose applications requiring robust and enduring adhesion.',
      package: '200ml, 400ml, 500ml',
      image: ' ',
    },
    steelFilledEpoxyPutty: {
      title: 'Steel Filled Epoxy Putty',
      description: `Steel-filled epoxy putty for general-purpose maintenance and repairs. Ideal for bonding, filling, and restoring metal parts.

      Key Features:
      
      Easy to apply, no special tools required.
      Bonds to metals and concrete.
      Resistant to chemicals, wear, and temperatures up to 121°C.
      USDA certified for food safety.

      Applications:
      
      Repair worn metal parts.
      Fill casting defects.
      Restore shafts, bearings, and valves.

      Properties:

      Color: Dark Gray     
      Hardness: 85D (Shore D)`,
      package: '500gr',
      image: ' ',
    },
    aluminiumFilledEpoxyPutty: {
      title: 'Aluminium Filled Epoxy Putty',
      description: `Repairs and restores aluminum parts, castings, and equipment.

Key Features:

Easy application, no special tools needed.
Aluminum-like finish, corrosion-resistant.
Strong adhesion to metals and concrete.

Applications:

Ideal for filling defects, repairing damaged parts, and replacing missing sections.

Properties:

Color: Aluminum
Hardness: 85D
Temp. Resistance: 49°C (wet), 121°C (dry)
`,
      package: '500gr',
      image: ' ',
    },
    fiveMinutesEpoxyPutty: {
      title: '5 Minutes Epoxy Putty',
      description: `Fast-curing, steel-filled epoxy for general maintenance and repair. Bonds, fills, and restores metal surfaces.

Key Features:

Cures in 5 minutes, functional in 1 hour.
Adheres to metal and concrete.
Easy to apply, no special tools needed.
Resistant to chemicals and heat (up to 93°C).

Applications:

Repairs worn metals, valves, pumps, shafts, and bearings.
Alternative to welding for patching castings.`,
      package: '500gr',
      image: ' ',
    },
    stainlessSteelEpoxyPutty: {
      title: 'Stainless Steel Epoxy Putty',
      description: `Stainless steel-filled, room-temperature curing epoxy putty. Ideal for repairing stainless steel equipment. Corrosion-resistant and food-grade certified.

Key Features:

High chemical resistance
Suitable for food and chemical industries
Works in wet and dry environments

Applications:

Repairs for dairy plants, tanks, and stainless steel parts

Properties:

Color: Dark Gray
Hardness: 85D
Working Time: 58 min (24°C)
Temperature Resistance: Wet: 49°C, Dry: 121°C
`,
      package: '500gr',
      image: ' ',
    },
    titaniumEpoxyPutty: {
      title: 'Titanium Epoxy Putty',
      description: `The product is a high-performance, titanium-reinforced epoxy paste designed for repairs of sensitive parts and critical equipment. It provides excellent chemical resistance and high pressure strength, with a temperature resistance of 177°C in dry environments and 66°C in wet conditions. It is ideal for use on bearings, worn-out seals, hydraulic rams, and valves.

Key Features:

Excellent chemical and pressure resistance.
Adheres well to various materials, providing a durable metallic surface.

Applications:
Bearing housings, damaged shafts, hydraulic rams, worn rings, and valves.`,
      package: '500gr',
      image: ' ',
    },
    wetSurfaceRepairEpoxyPutty: {
      title: 'Wet Surface Repair Epoxy Putty',
      description: `The Wet Surface Repair Epoxy Putty is a strong adhesive for repairs in wet and dry environments, including underwater. It bonds to metal, concrete, and wood, and is resistant to rust, chemicals, and solvents.

Key Features:

Cures at +4°C or higher.
Works in sweet and salty water.
Does not sag or flow.
Temperature resistance: 49°C in wet, 121°C in dry conditions.

Applications:

Ideal for pipes, tanks, boats, and underwater repairs.`,
      package: '500gr',
      image: ' ',
    },
    wearResistantEpoxyPutty: {
      title: 'Wear Resistant Epoxy Putty',
      description: `A ceramic-filled liquid epoxy designed for durable, long-lasting applications, ideal for metal molding, abrasive material processing, and precision work.


Key Properties:

High abrasion resistance for long-lasting durability.
Low friction for smoother operation and reduced wear.
No shrinkage during curing for precise results.
Excellent adhesion to various substrates, especially metals.
Thermal stability for high-temperature resistance in different environments.
Resistant to a wide range of chemicals including oils, acids, and solvents.
Easy application with a simple mixing and curing process.

Applications:

Molding abrasive materials
Protective coatings for wear-prone surfaces
Metal corrosion protection
Excellent for repair work on machines`,
      package: '500gr',
      image: ' ',
    },
    waterFindingPaste: {
      title: 'Water Finding Paste',
      description:
        'Water Finding Paste is a useful tool to detect the presence of water in your fuel tank. Apply it to a piece of string or a clean rod, and lower it to the bottom of the tank. The brown paste will turn red when in contact with water, indicating the depth of the water in the tank.',
      package: '70gr',
      image: ' ',
    },
    gasolineFindingPaste: {
      title: 'Gasoline Finding Paste',
      description:
        'Gasoline Finding Paste is a useful tool to detect the presence of Gasoline in your fuel tank. Apply it to a piece of string or a clean rod, and lower it to the bottom of the tank. The pink paste will turn red when in contact with gasoline, indicating the depth of the Gasoline in the tank.',
      package: '70gr',
      image: ' ',
    },
    siliconeHeatTransferPaste: {
      title: 'Silicone Heat Transfer Paste',
      description:
        "It is widely used to improve heat flow for spark plug, between heat sinks and heat generating electronic components such as CPU's, GPU's, and power components. Silicone thermal greases are preferred in high operating temperature applications where silicone migration is not a concern.",
      package: '500gr box, 1kg Box, 10kg Pail, 25kg Pail',
      image: ' ',
    },
    solderingPaste: {
      title: 'Soldering Paste',
      description:
        'Flux based on acid with pasty consistency for soft soldering of copper and copper alloys for copper pipe installations, radiator construction, plumbing, dip soldering, fitting manufacturing and other solder works. ',
      package: '70gr, 500gr, 1kg',
      image: ' ',
    },
  };
  return (
    <>
      <Header />
      <div className="productBody">
        <Productcard data={products.antiSeizeCopperPaste} />
        <Productcard data={products.antiSeizeNickelPaste} />
        <Productcard data={products.antiSeizeAluminiumPaste} />
        <Productcard data={products.antiSeizeCeramicPaste} />
        <Productcard data={products.siliconeGrease} />
        <Productcard data={products.graphiteSiliconeGrease} />
        <Productcard data={products.PTFESiliconeGrease} />
        <Productcard data={products.MoS2SiliconeGrease} />
        <Productcard data={products.smokeTestSpray} />
        <Productcard data={products.contactSpray} />
        <Productcard data={products.degreaserSpray} />
        <Productcard data={products.rustRemoverSpray} />
        <Productcard data={products.brakeCleanerSpray} />
        <Productcard data={products.waterFindingPaste} />
        <Productcard data={products.gasolineFindingPaste} />
        <Productcard data={products.siliconeHeatTransferPaste} />
        <Productcard data={products.solderingPaste} />
        <Productcard data={products.siliconeSealantRTV1} />
        <Productcard data={products.steelFilledEpoxyPutty} />
        <Productcard data={products.aluminiumFilledEpoxyPutty} />
        <Productcard data={products.fiveMinutesEpoxyPutty} />
        <Productcard data={products.stainlessSteelEpoxyPutty} />
        <Productcard data={products.titaniumEpoxyPutty} />
        <Productcard data={products.wetSurfaceRepairEpoxyPutty} />
        <Productcard data={products.wearResistantEpoxyPutty} />
      </div>
      {/* <BackToTop />*/}
    </>
  );
}

export default Products;
