import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Contactform from '../Components/Contactform';

function Contact() {
  return (
    <>
      <Header />

      <div
        className="contact"
        style={{
          minHeight: '100vh',
          paddingTop: '152px',
        }}
      >
        <h3>Alpa Industry (Hamburg Office)</h3>
        Friedrich-Ebert-Damm 93a 22047 Hamburg<br></br>
        Serpil Altiparmak <br></br>
        <a href="tel:+491781119226">+49 178 111 9226</a>
        <br></br>
        <a href="mailto:serpil@alpaendustri.com">serpil@alpaendustri.com</a>
        <br></br>
        <a href="mailto:alpa@alpaendustri.com">alpa@alpaendustri.com</a>
        <br></br>
        <a
          href="http://www.alpaendustri.com/iletisim/"
          target="_blank"
          style={{ textDecoration: 'underline', color: '#00a8ad93' }}
        >
          Contact Form
        </a>
      </div>
    </>
  );
}

export default Contact;
