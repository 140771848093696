import React from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { DropdownMenu, DropdownItem, Dropdown, Menu } from 'semantic-ui-react';

const Dropdownmenu = () => {
  const navigate = useNavigate();

  const handleItemClick = (value) => {
    navigate({
      pathname: '/Products',
      search: createSearchParams({ title: value }).toString(),
    });
  };

  return (
    <Menu vertical>
      <Dropdown item text="Products" id="ProductMenu">
        <DropdownMenu id="dropdownmenu">
          <Dropdown item text="Anti-Seize Pastes">
            <DropdownMenu id="submenu">
              <DropdownItem
                text="Anti-Seize Copper Paste"
                onClick={() => handleItemClick('Anti-Seize Copper Paste')}
              />
              <DropdownItem
                text="Anti-Seize Nickel Paste"
                onClick={() => handleItemClick('Anti-Seize Nickel Paste')}
              />
              <DropdownItem
                text="Anti-Seize Aluminium Paste"
                onClick={() => handleItemClick('Anti-Seize Aluminium Paste')}
              />
              <DropdownItem
                text="Anti-Seize Ceramic Paste"
                onClick={() => handleItemClick('Anti-Seize Ceramic Paste')}
              />
            </DropdownMenu>
          </Dropdown>

          <Dropdown item text="Sprays">
            <DropdownMenu id="submenu">
              <DropdownItem text="Contact Spray" onClick={() => handleItemClick('Contact Spray')} />
              <DropdownItem
                text="Degreaser Spray"
                onClick={() => handleItemClick('Degreaser Spray')}
              />
              <DropdownItem
                text="Smoke Test Spray"
                onClick={() => handleItemClick('Smoke Test Spray')}
              />
              <DropdownItem
                text="Rust Remover Spray"
                onClick={() => handleItemClick('Rust Remover Spray')}
              />
              <DropdownItem
                text="Brake Cleaner Spray"
                onClick={() => handleItemClick('Brake Cleaner Spray')}
              />
            </DropdownMenu>
          </Dropdown>

          <Dropdown item text="Adhesives & Sealants">
            <DropdownMenu id="submenu">
              <DropdownItem
                text="Neutral Silicone Adhesive (RTV1)"
                onClick={() => handleItemClick('Neutral Silicone Adhesive (RTV1)')}
              />
              <DropdownItem
                text="Steel Filled Epoxy Putty"
                onClick={() => handleItemClick('Steel Filled Epoxy Putty')}
              />
              <DropdownItem
                text="Aluminium Filled Epoxy Putty"
                onClick={() => handleItemClick('Aluminium Filled Epoxy Putty')}
              />
              <DropdownItem
                text="Stainless Steel Epoxy Putty"
                onClick={() => handleItemClick('Stainless Steel Epoxy Putty')}
              />
              <DropdownItem
                text="Titanium Epoxy Putty"
                onClick={() => handleItemClick('Titanium Epoxy Putty')}
              />
              <DropdownItem
                text="Wet Surface Repair Epoxy Putty"
                onClick={() => handleItemClick('Wet Surface Repair Epoxy Putty')}
              />
              <DropdownItem
                text="Wear Resistant Epoxy Putty"
                onClick={() => handleItemClick('Wear Resistant Epoxy Putty')}
              />
            </DropdownMenu>
          </Dropdown>

          <Dropdown item text="Silicone Greases">
            <DropdownMenu id="submenu">
              <DropdownItem
                text="Silicone Grease"
                onClick={() => handleItemClick('Silicone Grease')}
              />
              <DropdownItem
                text="Graphite Silicone Grease"
                onClick={() => handleItemClick('Graphite Silicone Grease')}
              />
              <DropdownItem
                text="PTFE Silicone Grease"
                onClick={() => handleItemClick('PTFE Silicone Grease')}
              />
              <DropdownItem
                text="MoS₂ Silicone Grease"
                onClick={() => handleItemClick('MoS₂ Silicone Grease')}
              />
            </DropdownMenu>
          </Dropdown>
          <DropdownItem
            text="Silicone Heat Transfer Paste"
            onClick={() => handleItemClick('Silicone Heat Transfer Paste')}
          />

          <DropdownItem
            text="Water Finding Paste"
            onClick={() => handleItemClick('Water Finding Paste')}
          />
          <DropdownItem
            text="Gasoline Finding Paste"
            onClick={() => handleItemClick('Gasoline Finding Paste')}
          />
          <DropdownItem text="Soldering Paste" onClick={() => handleItemClick('Soldering Paste')} />
        </DropdownMenu>
      </Dropdown>
    </Menu>
  );
};

export default Dropdownmenu;
